import React from 'react';

import { RouteComponentProps, Router } from '@reach/router';
import Layout from 'src/components/layout/layout';
import LevelConfigEdit from 'src/components/level-config-edit/level-config-edit';
import { PrivateRoute } from 'src/components/private-route/private-route';
import SEO from 'src/components/seo';

const EditLevelConfigPage = () => {
  return (
    <Router basepath="/tournaments/level-config">
      <EditLevelConfigRoute path="/:levelId" />
    </Router>
  );
};

interface EditLevelConfigProps extends RouteComponentProps {
  levelId?: string;
}

const EditLevelConfigRoute: React.FC<EditLevelConfigProps> = ({ levelId }) => {
  return (
    <Layout>
      <SEO title="Edit Level Configuration" />
      <PrivateRoute>
        <LevelConfigEdit levelId={levelId} />
      </PrivateRoute>
    </Layout>
  );
};

export default EditLevelConfigPage;
